import type { Organism } from '@verndale/core';
// import globModules from './glob-modules';

const modules: Organism[] = [
  /*{
    name: 'gallery',
    loader: () => import('./modules/gallery')
  },*/
  {
    name: 'form',
    loader: () => import('./modules/form')
  },
  {
    name: 'header',
    loader: () => import('./modules/header')
  },
  {
    name: 'legal',
    loader: () => import('./modules/legal')
  },
  {
    name: 'staff-card',
    loader: () => import('./modules/staff-card')
  },
  {
    name: 'reviews',
    loader: () => import('./modules/reviews')
  }
];

export default [/*...globModules,*/...modules];
